import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { CiMenuBurger } from "react-icons/ci";

function Navber() {
  return (
    <Navbar collapseOnSelect expand="lg" className="nav-color ">
    <Container>
      <Navbar.Brand className="colorr fs-3" href="#home">Your X</Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav ">
      <CiMenuBurger className='text-white' />
      </Navbar.Toggle>
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto">
          <Nav.Link  className="colorr"href="#features">Roadmap</Nav.Link>
          <Nav.Link  className="colorr"href="#pricing">About</Nav.Link>
         
        </Nav>
        <Nav>
          {/* <Nav.Link  className="colorr"href="#deets">More deets</Nav.Link> */}
          
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
  )
}

export default Navber