import Button from 'react-bootstrap/Button';
import './Twitter.css'
import { FaSquareXTwitter } from "react-icons/fa6";
import { BsTelegram } from "react-icons/bs";
function Twiter() {
  return (
    <div style={{marginTop:"23%"}} className='  d-flex justify-content-center gap-2 '>
        
         <a  className='button-27' href="http://x.com/yxcoins" target='_blank'><FaSquareXTwitter className='fs-2' />Twitter</a>
         <a className='button-27' href="https://t.me/YXCOINPortal" target='_blank'><BsTelegram className='fs-2' /> Telegram</a>
       
    </div>
  )
}

export default Twiter