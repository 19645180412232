import './App.css';
import Navber from './Compounent/Navber/Navber';
import Twiter from './Compounent/Twiter/Twiter';

function App() {
  return (
    <div className="App back">
     <Navber/>
     <Twiter/>
    </div>
  );
}

export default App;
